<template>
  <div>
    <v-sheet
      v-show="gateReportStore.showControls"
      v-resize-observer="onContentResized"
      class="tabs z-index-0"
    >
      <v-tabs height="35">
        <v-tab
          v-if="isAnprBasedProject"
          :to="`/gate-report/${$route.params.exid}/anpr`"
          nuxt
        >
          ANPR
        </v-tab>
        <v-tab :to="`/gate-report/${$route.params.exid}/events`" nuxt>
          Events
        </v-tab>
        <v-tab :to="`/gate-report/${$route.params.exid}/roi`" nuxt> ROI </v-tab>
        <v-tab :to="`/gate-report/${$route.params.exid}/params`" nuxt>
          Parameters
        </v-tab>
        <v-tab :to="`/gate-report/${$route.params.exid}/logs-history`" nuxt>
          Logs
        </v-tab>
      </v-tabs>
    </v-sheet>

    <portal-target name="hovered-thumbnails-container" />

    <NuxtChild
      ref="content"
      v-resize-observer="onContentResized"
      :style="contentStyle"
    />
  </div>
</template>

<script>
import { useLayoutStore } from "@/stores/layout"
import { useGateReportStore } from "@/stores/gateReport"
import { mapStores } from "pinia"
import { ProjectFeatureFlag } from "@evercam/shared/types"
import { PERMISSIONS } from "@/constants/permissions"

export default {
  meta: {
    requiredPermissions: [PERMISSIONS.GATE_REPORT.VIEW],
  },
  name: "GateReportCamera",
  async asyncData({ route, params, redirect }) {
    const gateReportStore = useGateReportStore()
    if (!gateReportStore.selectedProject) {
      await gateReportStore.init(params.exid)
    }
    const isAnprBasedProject =
      gateReportStore.selectedProject?.featureFlags?.includes(
        ProjectFeatureFlag.GateReportAnprBased
      )
    if (route.name === "gate-report-exid") {
      if (isAnprBasedProject) {
        return redirect(`/gate-report/${params.exid}/anpr`)
      } else {
        return redirect(`/gate-report/${params.exid}/events`)
      }
    }
    useLayoutStore().isMiniSidebar = true
  },
  data() {
    return {
      contentStyle: {},
    }
  },
  computed: {
    ...mapStores(useGateReportStore),
    isAnprBasedProject() {
      return this.gateReportStore.selectedProject?.featureFlags?.includes(
        ProjectFeatureFlag.GateReportAnprBased
      )
    },
  },
  created() {
    this.$root.$on("expand-event-table", (showControls) => {
      this.showTabs = showControls
    })
  },
  methods: {
    onContentResized() {
      this.$setTimeout(() => {
        const content = this.$refs?.content?.$el?.getBoundingClientRect()
        this.contentStyle = {
          height: `${window.innerHeight - content?.top || 0}px !important`,
        }
      }, 500)
    },
  },
}
</script>

<style scoped>
.tabs {
  position: relative;
  z-index: 99;
  box-shadow: 0 14px 10px -14px #0000002e;
}
</style>
